import React from 'react'
import {useEffect, useState} from 'react'
import axios from 'axios'
import { Link } from "react-router-dom";

const Traders=()=> {
  const [traders, setTraders]= useState([]);

  useEffect(()=>{
    const fetchAllTraders= async ()=>{
        try{
          const res=await axios.get("https://market.fittingintelligence.com/traders");
          // console.log(res)
          setTraders(res.data);
        }catch(err){
          console.log(err);
        }
    }
    fetchAllTraders()
  },[]);


  return (

    <div className="appSec">
    <div className="appSecHeader">All Trader Profiles</div>

    <div className="appSecBody">
      <table>
      <tr>
        <th width="100">trader_profile_id</th>
        <th width="100">trader_id</th>
        <th width="100">trader_name</th>
        <th width="100">proprietor_name</th>
        <th width="100">allocated_sites</th>
        <th width="100">bio</th>
        <th width="100">website</th>
        <th width="100">mobile</th>
        <th width="100">phone</th>
        <th width="100">email</th>
        <th width="100">postal_address</th>
        <th width="100">post_code</th>
        <th width="100">desc_of_goods</th>
        <th width="100">can_promoteYN</th>
        <th width="100">insurance_co_name</th>
        <th width="100">insurance_policy_num</th>
        <th width="100">insurance_expiry</th>
        <th width="100">insurance_scan_attachment_id</th>
        <th width="100">food_handling_cert_num</th>
        <th width="100">food_handling_cert_expiry</th>
        <th width="100">food_handling_scan_attachment_id</th>
        <th width="100">city_of_monash_s_o_trade</th>
        <th width="100">city_of_monash_s_o_trade_expiry</th>
        <th width="100">city_of_monash_s_o_trade_scan_attachment_id</th>
        <th width="100">referred_by</th>
        <th width="100">accepted_toc_date</th>
        <th width="100">insert_ts</th>
      </tr>
    {traders.map(trader=>(

      <tr>
      {Object.keys(trader).map(x=>{
        return <td class='col' width="100">  {trader[x]}  </td>;
      }
      )}
      
      <br/>
          <button className='update'><Link to={`/update/${trader.id}`}>Update</Link></button>
      </tr>
    ))}

    </table>
    </div>
      </div>
      
  );
}

export default Traders