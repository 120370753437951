import React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from "react-router-dom";

const RunSheets = () => {
  const [runSheets, setrunSheets] = useState([]);

  useEffect(() => {
    const fetchAllrunSheets = async () => {
      try {
        const res = await axios.get("https://market.fittingintelligence.com/runSheet");
        // console.log(res)
        setrunSheets(res.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchAllrunSheets()
  }, []);


  return (
    <div>
      <div className="appSecHeader"></div>

      <div className="appSecBody">
        <table>
          <thead className="glassMorph">
          <tr>
            <th width="100">Sites</th>
            <th width="100">Trader</th>
            <th width="100">Tix</th>
            <th width="100">Market</th>
            <th width="100">Status</th>
            <th width="100">RRP</th>
            <th width="100">Actions</th>
          </tr>
          
        </thead>
        <tbody>



          {runSheets.map(runSheet => (

           <tr className="runsheet" key={runSheet.sites}>
              <td width="100">{runSheet.sites}</td>
              <td width="100">{runSheet.trader_name}</td>
              <td width="100">{runSheet.tix}</td>
              <td width="100">{runSheet.mkt_date}</td>
              <td width="100">{runSheet.status}</td>
              <td width="100">{runSheet.rrp}</td>
              <td width="100">

              <div class='buttons'>
  <a href='#' title='Title 1'>Renew
    <i data-feather="coffee"></i>
  </a>
  <a href='#' title='Title 3'>Update
    <i data-feather="heart"></i>
  </a>
  <a href='#' title='Title 4'>Comment
    <i data-feather="link-2"></i>
  </a>
</div>
                {/* <button className='buttons'>
                  <Link to={`/update/${runSheet.id}`}>Renew</Link>
                </button>

                <button className='buttons'>
                  <Link to={`/update/${runSheet.id}`}>Renew</Link>
                </button>

                <button className='buttons'>
                  <Link to={`/update/${runSheet.id}`}>Renew</Link>
                </button>                 */}
              </td>
            </tr>

          ))}
</tbody>

        </table>
      </div>
    </div>
  );
}

export default RunSheets