import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom"

const Add = () => {
  const [trader, setTrader] = useState({
    trader_id: null,
    allocated_sites: null,
    proprietor_name: null,
    bio: null,
    website: null,
    mobile: null,
    phone: null,
    email: null,
    postal_address: null,
    post_code: null,
    desc_of_goods: null,
    can_promoteYN: null,
    insurance_co_name: null,
    insurance_policy_num: null,
    insurance_expiry: null,
    insurance_scan_attachment_id: null,
    food_handling_cert_num: null,
    food_handling_cert_expiry: null,
    food_handling_scan_attachment_id: null,
    city_of_monash_s_o_trade: null,
    city_of_monash_s_o_trade_expiry: null,
    city_of_monash_s_o_trade_scan_attachment_id: null,
    referred_by: null,
    accepted_toc_dat: null

  });

  const navigate = useNavigate()

  const handleChange = (e) => {
    const isValid =e.target.checkValidity()

    try {
    if (!isValid){
      let x = document.getElementById(e.target.name)
      x.hidden=false
    }
    else{
      let x = document.getElementById(e.target.name)
      x.hidden=true
    }}
    catch{}
    setTrader((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeMS = (e) => {
    let targetVal = []
    for (let item of e.target.selectedOptions) targetVal.push(item.value)
    setTrader((prev) => ({ ...prev, [e.target.name]: targetVal.join() }));
  };

  console.log(trader)

  const handleClick = async e => {
    e.preventDefault()
    const isValid = e.target.checkValidity();
    console.log(e)
    if (isValid)
    {
    try {
      await axios.post("https://market.fittingintelligence.com/trader", trader)
      // navigate("/")
      console.log('TraderPosted')
    } catch (err) {
      console.log(err)
    }}
    else 
    console.log('form invalid')
  }



  return (
    <div className="appSec">
      <form id="form">
      <div className="appSecHeader">Update your details</div>
      <div className="appSecBody">
        <div className='form'>
          <hidden type='number' placeholder='trader_id' onChange={handleChange} name='trader_id' />

          <label for="allocated_sites">allocated sites
          
          </label> 
          
          
            <input type='text' placeholder='allocated_sites' onChange={handleChange} name='allocated_sites' required/>
            <span id = 'allocated_sites' class="validation" hidden>Please enter your allocated sites</span>

          <label for="proprietor_name">proprietor name</label>                                                         <input type='text' placeholder='proprietor_name' onChange={handleChange} name='proprietor_name' />
          <label for="bio">bio</label>
             <textarea  placeholder='bio' onChange={handleChange} name='bio' rows="5"  />



          <label for="website">website</label>                                                                         <input type='url' placeholder='website' onChange={handleChange} name='website' />
          <label for="mobile">mobile</label>                                                                           <input type='text' placeholder='mobile' onChange={handleChange} name='mobile' />
          <label for="phone">phone</label>                                                                             <input type='tel' placeholder='phone' onChange={handleChange} name='phone' pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
          <label for="email">email</label>                                                                             <input type='email' placeholder='email' onChange={handleChange} name='email' />
          <label for="postal_address">postal address</label>                                                           <input type='text' placeholder='postal_address' onChange={handleChange} name='postal_address' />
          <label for="post_code">post code</label>                                                                     <input type='text' placeholder='post_code' onChange={handleChange} name='post_code' />
          <label for="desc_of_goods">desc of goods</label>
          <select onChange={handleChangeMS} name='desc_of_goods' multiple required>
            <option value="flowers">flowers</option>
            <option value="food">food</option>
            <option value="2nd hand goods">2nd Hand Goods</option>
          </select>
          <label for="can_promoteYN">Do you authorise RotaryOCH to promote you</label>
             <input type='checkbox' placeholder='can_promoteYN' onChange={handleChange} name='can_promoteYN' />


          <label for="insurance_co_name">insurance co name</label>                                                     <input type='text' placeholder='insurance_co_name' onChange={handleChange} name='insurance_co_name' />
          <label for="insurance_policy_num">insurance policy num</label>                                               <input type='text' placeholder='insurance_policy_num' onChange={handleChange} name='insurance_policy_num' />
          <label for="insurance_expiry">insurance expiry</label>                                                       <input type='date' placeholder='insurance_expiry' onChange={handleChange} name='insurance_expiry' />
          <label for="insurance_scan_attachment_id">insurance scan attachment id</label>                               <input type='text' placeholder='insurance_scan_attachment_id' onChange={handleChange} name='insurance_scan_attachment_id' />
          <label for="food_handling_cert_num">food handling cert num</label>                                           <input type='text' placeholder='food_handling_cert_num' onChange={handleChange} name='food_handling_cert_num' />
          <label for="food_handling_cert_expiry">food handling cert expiry</label>                                     <input type='date' placeholder='food_handling_cert_expiry' onChange={handleChange} name='food_handling_cert_expiry' />
          <label for="food_handling_scan_attachment_id">food handling scan attachment id</label>                       <input type='text' placeholder='food_handling_scan_attachment_id' onChange={handleChange} name='food_handling_scan_attachment_id' />
          <label for="city_of_monash_s_o_trade">city of monash s o trade</label>                                       <input type='text' placeholder='city_of_monash_s_o_trade' onChange={handleChange} name='city_of_monash_s_o_trade' />
          <label for="city_of_monash_s_o_trade_expiry">city of monash s o trade expiry</label>                         <input type='date' placeholder='city_of_monash_s_o_trade_expiry' onChange={handleChange} name='city_of_monash_s_o_trade_expiry' />
          <label for="city_of_monash_s_o_trade_scan_attachment_id">city of monash s o trade scan attachment id</label> <input type='text' placeholder='city_of_monash_s_o_trade_scan_attachment_id' onChange={handleChange} name='city_of_monash_s_o_trade_scan_attachment_id' />
          <label for="referred_by">referred by</label>                                                                 <input type='text' placeholder='referred_by' onChange={handleChange} name='referred_by' />
          <label for="accepted_toc_dat">accepted toc dat</label>                                                              <input type='date' placeholder='accepted_toc_dat' onChange={handleChange} name='accepted_toc_dat' />
          <button onClick={handleClick}>Add</button>
          <button>submit</button>
        </div>
      </div>
      </form>
    </div>
  )
}

export default Add