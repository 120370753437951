

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";



import RunSheet from "./pages/runSheet";
import Add from "./pages/Add";
import Update from "./pages/Update";
import AddTrader from "./pages/addTrader";
import Traders from "./pages/Traders";
import TraderInfo from "./pages/TraderInfo";
import "./style.css"
import "./main_style.css"
import "./buttons.css"


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RunSheet/>}/>
          <Route path='/add' element={<Add/>}/>
          <Route path='/update/:id' element={<Update/>}/>
          <Route path="/addTrader" element={<AddTrader/>}/>
          <Route path="/Traders" element={<Traders/>}/>
          <Route path="/TraderInfo/:trader_id" element={<TraderInfo/>}/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
